<template>
  <div class="content p-0">
    <div class="login-wrapper">
      <div class="row m-0">
        <div
          class="col-lg-4 h-100-vh side-banner"
          style="background-image:url('/static/images/bg-login.jpg');background-position: center center; background-size: cover;"
        >
          <div class="content px-5 text-center d-flex justify-content-center h-100">
            <div class="align-self-center">
              <img
                src="/static/images/logo-white.png"
                width="150"
                class="mb-4"
                alt=""
              >
              <h4 class="text-white">
                Discover great places in world
              </h4>
              <p class="text-white">
                Find awesome places, bars, restaurants and activities in London
              </p>
              <a
                href="JavaScript:Void(0)"
                class="btn btn-success"
              >Search</a>
            </div>
          </div>
        </div>
        <div class="col-lg-8 site-content">
          <div class="content">
            <div class="row">
              <div class="col-lg-7 mx-auto">
                <div class="text-center mb-4">
                  <h2>Forgot Password?.</h2>
                  <p>Enter the Registered Email</p>
                </div>
                <div class="card bg-secondary shadow border-0">
                  <div class="card-body px-lg-5 py-lg-5">
                    <form role="form">
                      <div class="form-group mb-3">
                        <div class="input-group input-group-alternative">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-envelope" /></span>
                          </div>
                          <input
                            class="form-control"
                            placeholder="Email"
                            type="email"
                          >
                        </div>
                      </div>
                      <div class="text-center">
                        <button
                          type="button"
                          class="btn btn-primary mt-4"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
